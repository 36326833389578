<template>
    <div>
		<BHLoading v-if="loading" />
        <a-table :rowKey="(e) => e.id" class="white-table" :columns="columns" :data-source="formGroups" :pagination="false">
            <div slot="name" slot-scope="obj" class="dF aC">
                <div class="mr-3">{{obj.name}}</div>
            </div>
            <div slot="action" slot-scope="obj" class="dF jE">
                <svg @click="editGroup(obj)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 editIcon mr-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                <i @click="deleteGroup(obj)" class="fe fe-trash-2 deleteIcon" style="font-size: 16px; "/>
            </div>
        </a-table>

        <div @click="addNewGroup" class="dF aC" style="color:var(--orange); cursor:pointer; width: 110px; margin-top:24px">
            <a-icon style="font-size:25px" type="plus-circle" class="mr-3"/>
            <div>Add Group</div>
        </div>

		<a-modal :width="'510px'" centered :header="null" :footer="null" :visible="showGroupModal" @cancel="cancelGroup">
			<div>
				<h5>{{ newGroup.editing ? 'Edit Group' : 'Add New Group' }}</h5>
				<a-row :gutter="16" class="mt-4">
					<a-form-model ref="newGroup" :model="newGroup">
						<a-col :span="24">
							<a-form-model-item prop="name" label="Group Name" required :rules="req('Please enter the Name')">
								<a-input v-model="newGroup.name" placeholder="Enter the Name"></a-input>
							</a-form-model-item>
						</a-col>
					</a-form-model>
				</a-row>
				<div class="dF mt-3" style="justify-content:flex-end">
					<a-button @click="cancelGroup" size="large" class="cancel-button">CANCEL</a-button>
					<a-button @click="submitGroup" type="primary" size="large" class="ml-3">{{ newGroup.editing ? 'UPDATE' : 'CREATE' }}</a-button>
				</div>
			</div>
		</a-modal>
    </div>
</template>

<script>

import BHLoading from 'bh-mod/components/common/Loading'

export default {
    props:[],
	components:{
		BHLoading
	},
    computed:{

    },
    data(){
        return{
			loading:false,
            defaultSources:['nosource'],
            columns:[
                {
                    title: 'Name',
                    key: 'name',
                    scopedSlots: { customRender: 'name' },
                },
                {
                    title: 'Actions',
                    key: 'action',
                    align: 'right',
                    scopedSlots: { customRender: 'action' },
                },
            ],
			formGroups:[],
			newGroup:{
				editing:false,
                name:'',
                id:0,
            },
			showGroupModal:false,
        }
    },
	methods:{
		req: msg => ({ required: true, message: msg }),
		addNewGroup() {
			console.log('ADDING NEW GROUP...')
			this.showGroupModal = true
		},
		editGroup(item){
			console.log('EDITING GROUP...', item)
			this.newGroup = {
				editing:true,
				name:item.name,
				id:item.id
			}
			this.showGroupModal = true
		},
		submitGroup() {
			console.log('SUBMITTING GROUP...')
            this.$refs['newGroup'].validate(valid => {
                if (valid) {
                    console.log('newGroup', this.newGroup)
                    let contains = false
                    this.formGroups.forEach( x => {
                        console.log('X')
                        if (x.name.toLowerCase() == this.newGroup.name.toLowerCase()){
                            contains = true
                            return this.$message.error('Group already exists!')
                        }
                    })
                    if (!contains){
						this.loading = true
						if (this.newGroup.editing){
							let sendObj = {
								id:this.newGroup.id,
								name:this.newGroup.name,
								instance:this.$store.state.instance.id
							}
							this.$api.put(`/groups/${this.newGroup.id}`, sendObj).then( ({data}) => {
								console.log('DATA COMING BACK', data)
								this.loading = false
								let index = this.formGroups.findIndex( x => x.id == this.newGroup.id)
								this.formGroups.splice(index, 1, data)
								this.cancelGroup()
							}).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
						} else {
							let sendObj = {
								name:this.newGroup.name,
								instance:this.$store.state.instance.id
							}
							console.log('SEND OBJ', sendObj)
							this.$api.post('/groups', sendObj).then( ({data}) => {
								console.log('DATA COMING BACK', data)
								this.loading = false
								this.formGroups.push(data)
								this.cancelGroup()
							}).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
						}
                    }
                } else {
                    console.log('error submit!!');
               		return false;
                }
            })
        },
        cancelGroup(){
			console.log('CANCEL GROUP...')
            this.$refs['newGroup'].resetFields()
            this.newGroup = {
                name:'',
                id:0
            },
            this.showGroupModal = false
        },
        deleteGroup(item) {
            let self = this
            if (this.$p < 40){
                return this.$message.error('You do not have permission to delete Groups')
            }
            this.$confirm({
            title: "Delete Group",
            content: h => <div>Do you want to delete this group?</div>,
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            centered: true,
            onOk() {
                console.log('DELETING GROUP...', item)
				self.loading = true;
                self.$api.delete(`/groups/${item.id}`).then( ({data}) => {
                    console.log('DATA COMING BACK', data)
					self.loading = false
					let index = self.formGroups.findIndex( x => x.id == item.id)
					self.formGroups.splice(index, 1)
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						self.$message.error(self.$err(err))
					}
				})
            },
            onCancel() {
                console.log('Cancel')
            }
            })
        },
	},
	async created() {
		this.loading = true
		try {
			let { data } = await this.$api.get('/groups/:instance')
			console.log('DATA COMING BACK', data)
			this.formGroups = data
			this.loading = false
		} catch (err) {
			this.loading = false
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				this.$message.error(this.$err(err))
			}
		}
	}
}
</script>

<style lang="scss" scoped>
    .editIcon {
        color: #9EA0A5;
        cursor: pointer;
        transition:color .1s ease-in;
    }
    .editIcon:hover {
        color: var(--orange);
        transition:color .1s ease-in;
    }
    .deleteIcon {
        color: #9EA0A5;
        cursor: pointer;
        transition:color .1s ease-in;
    }
    .deleteIcon:hover {
        color: var(--danger);
        transition:color .1s ease-in;
    }
</style>
